
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






























































































































































































































































































































































































































.submit {
  @include loading($c-orange);

  margin-left: auto;
}

.form__gdpr {
  .action__label {
    font-size: 1.8rem;
  }
}
